import '../css/Six.css';
import G1 from '../Images/G1.jpg';
import G2 from '../Images/G2.jpg';

function Six() {
  return (
    <div className="six-container">
      {/* Main Headline */}
      <div className="text-center page-section">
        <h1 className="headline">A Trusted Partner to Today's Top Healthcare Providers</h1>
        <p className="intro-paragraph">
          We specialize in providing comprehensive solutions tailored to the needs of healthcare providers, ensuring excellence in patient care and operational efficiency.
        </p>
      </div>

      {/* Section Containers */}
      <div className="row section-container">
        {/* Hospitals and Health Systems Section */}
        <div className="col-md-6 section animated-slide">
          <h2 className="section-title">Hospitals and Health Systems</h2>
          <div className="image-text-wrapper">
            <img src={G1} alt="Hospitals and Health Systems" className="img-fluid section-image animated-fade" />
            <p className="section-paragraph">
              Our partnership with hospitals and health systems focuses on optimizing operational processes, improving patient care, and ensuring compliance with regulatory standards.
            </p>
          </div>
          <div className="text-center">
            <a href="#hospitals" className="link-button animated-bounce">Learn More</a>
          </div>
        </div>

        {/* Physicians and Specialty Care Section */}
        <div className="col-md-6 section animated-slide">
          <h2 className="section-title">Physicians and Specialty Care</h2>
          <div className="image-text-wrapper">
            <img src={G2} alt="Physicians and Specialty Care" className="img-fluid section-image animated-fade" />
            <p className="section-paragraph">
              We provide tailored solutions for physicians and specialty care providers to enhance patient engagement, streamline processes, and improve overall practice management.
            </p>
          </div>
          <div className="text-center">
            <a href="#physicians" className="link-button animated-bounce">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Six;