import React from 'react';
import '../css/Home.css'; // Make sure to import the CSS file
import '../Pages/Home'
import '../comp/logo file/Group 24.png'
import logo1 from '../comp/logo file/Group 24.png';
import '../Pages/Thirdpage.js'
import Thirdpage from '../Pages/Thirdpage.js';
import Secondpage from './Secondpage.js';
import Fourth from './Fourthpage.js';
import Five from './Five.js';
import Six from './six.js';

import Eight from './Eight.js';
import Ten from './Ten.js';
import Eleven from './Eleven.js';
import Contact from './Contact.js';
import Footer from './Footer.js';
import MessageBox from './MessageBox.js';





function Home() {
  return (
    <>
    <div className="home-container">
      <div className="background-image">
        <div className="bottom-box">
          <h2>Building a world of health around every consumer </h2>
          <p>Your trusted partner for healthcare services</p>
          <button>Learn More</button>
        </div>
      </div>
    </div>


  <div>
    <Secondpage/>
    </div>

 
  <div>
    <Thirdpage/>
  </div>
 


  <div>
    <Fourth/>
  </div>

    

  <div>
    <Five/>
  </div>



  <div>
    <Six/>
  </div>





  <div>
    <Eight/>
  </div>



  <div>
    <Ten/>
  </div>



  <div>
    <Eleven/>
  </div>



  <div>
    <Contact/>
  </div>



  <div>
    <Footer/>
  </div>

<div>
  <MessageBox/>
</div>


</>
  );
}

export default Home;