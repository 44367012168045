import React, { useState } from 'react';
import '../css/MessageBox.css';

const MessageBox = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMessageBox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`message-box-container ${isOpen ? 'open' : ''}`}>
      <button onClick={toggleMessageBox} className="message-box-toggle">
        {isOpen ? 'Close' : 'DMS HELP'}
      </button>

      {isOpen && (
        <div className="message-box">
          <h3>How do you feel today?</h3>
          <textarea placeholder="Share your feelings or symptoms..." />
          <button className="submit-btn">Submit</button>
        </div>
      )}
    </div>
  );
};

export default MessageBox;