import { Link } from "react-router-dom";
import '../css/Footer.css';

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          {/* Company Section */}
          <div className="footer-links">
            <h3>DMS Solution</h3>
            <ul>
              <li><Link to="/about-us">Patient Access</Link></li>
              <li><Link to="/careers">Health Information Management</Link></li>
              <li><Link to="/contact">Extended Business Office</Link></li>
              <li><Link to="/privacy-policy">Analytics & Reporting</Link></li>
            </ul>
          </div>

          {/* Services Section */}
          <div className="footer-links">
            <h3>DCM Services</h3>
            <ul>
              <li><Link to="/services/patient-access">Patient Access Services</Link></li>
              <li><Link to="/services/financial-clearance">HIM And Clinical Services</Link></li>
              <li><Link to="/services/clinical-services">Patient Financial Services</Link></li>
              <li><Link to="/services/medical-coding">RCA Development Services</Link></li>
            </ul>
          </div>

          {/* Resources Section */}
          <div className="footer-links">
            <h3>Company</h3>
            <ul>
              <li><Link to="/blog">Leadership</Link></li>
              <li><Link to="/faq">News&Events</Link></li>
              <li><Link to="/case-studies">Associations & Partners</Link></li>
              <li><Link to="/testimonials">Diversity & Inclusion</Link></li>
            </ul>
          </div>
        </div>

        

        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} DCM RCM. All Rights Reserved.</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
