import React from 'react';
import '../css/Eleven.css';
import Article1 from '../Images/Article1.jpg';
import Article2 from '../Images/Article2.webp';
import Article3 from '../Images/Article3.webp';

function Eleven() {
  const articles = [
    {
      title: 'Understanding Healthcare Trends',
      image: Article1,
      description: 'An in-depth look at the latest trends shaping the healthcare industry in 2024.',
    },
    {
      title: 'The Future of Medical Technology',
      image: Article2,
      description: 'How technology is revolutionizing the way we approach healthcare services.',
    },
    {
      title: 'Patient-Centered Care',
      image: Article3,
      description: 'Focusing on patient needs to deliver more personalized and effective treatments.',
    },
  ];

  return (
    <div className="container insights-container">
      <h1>Our Insights & Articles</h1>
      <div className="article-grid">
        {articles.map((article, index) => (
          <div key={index} className="article-box">
            <img src={article.image} alt={article.title} className="article-image" />
            <h2>{article.title}</h2>
            <p>{article.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Eleven;