import React, { useState } from "react";
import '../css/Thirdpage.css';
import Medicalbilling from '../Images/Medicalbilling1.jpg'
import Medicalbilling2 from '../Images/MedicalBilling2.jpg'

function HelpPage() {
  const [selectedService, setSelectedService] = useState("Cash Flow Management");

  const services = [
   "Cash Flow Management",
   "In Accurate RAF Score",
   "Increase In Deniel",
   "Errors In Coding",
   "Incorrect Patient Statement",
   "Inconsistency In Obtaining Authorization",
   "Inconsistency In Tracking POS Collections",
   "Rejections And Denials",
   "Untimely Followup On Patient Balances",
   "High Eligibility Related Denials",
   "Incomplete Resolution",
   "High AR In 90+Aging Bucket",
   "Higher Unbilled Claims",
   "Untimely Follow-up On Under Paid Claims",
   "Refunds Not Processed",
   "Compiliance Issues With Payers",
   "Reconciliation Of Payment Batches",
   "High Claim Rejections"
  ];

  return (
    <>
      <div className="container help-page">
        <div className="row">
          {/* Left Side Custom Dropdown Menu */}
          <div className="col-md-4">
            <div className="dropdown-container">
              <h1>How Can We Help You?</h1>
              <ul className="service-list">
                {services.map((service, index) => (
                  <li 
                    key={index} 
                    className={`service-item ${selectedService === service ? "active" : ""}`}
                    onClick={() => setSelectedService(service)}
                  >
                    {service}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Right Side Boxes */}
          <div className="col-md-8">
            <div className="row">
              {/* First Large Box */}
              <div className="col-md-6 mb-3">
                <div className="box box-large">
                  <img 
                    src={Medicalbilling} 
                    alt="Box 1" 
                    className="img-fluid" 
                  />
                 <p style={{ fontFamily: 'Georgia, serif', fontSize: '18px', fontWeight: 'bold', fontStyle: 'italic', color: '#333' }}>
                    Medical Coding - Our team uses specialized tech to identify errors, mitigate compliance risk, and streamline coding.
                  </p>
                  <a href="#box1" className="box-link">Learn More</a>
                </div>
              </div>

              {/* Two Medium Boxes */}
              <div className="col-md-6 mb-4">
                <div className="box box-medium">
                  <img 
                    src={Medicalbilling2} 
                    alt="Box 2" 
                    className="img-fluid" 
                  />
                 <p style={{ fontFamily: 'Georgia, serif', fontSize: '18px', fontWeight: 'bold', fontStyle: 'italic', color: '#333' }}>
  Health Information Management Norwegian American Hospital Saves Over 40% With Strategic HIM Outsourcing
</p>
                  <a href="#box2" className="box-link">Learn More</a>
                </div>
              </div>

              <div className="col-md-6 mb-5">
                <div className="box box-medium">
                <h1 style={{ color: "blue" }}>8.2 M+</h1>
                
                  <a href="#box3" className="box-link">Learn More</a>
                </div>
              </div>

              {/* Two Small Boxes */}
              <div className="col-md-3 mb-1">
                <div className="box box-small">
                 <h1 style={{color:'blue'}}>4%</h1>
                  
                  <a href="#box4" className="box-link">Learn More</a>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <div className="box box-small">
                  <h1 style={{color:"blue"}}>ICODE WORK FLOW </h1>
                                   <p className="box-description"></p>
                  <a href="#box5" className="box-link">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpPage;