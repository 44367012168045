import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'

import Navbar from './comp/Navbar'
import Home from './Pages/Home';
import Secondpage from './Pages/Home';
import Thirdpage from './Pages/Thirdpage';
import Fourth from './Pages/Fourthpage';
import Five from './Pages/Five';
import Six from './Pages/six';

import Eight from './Pages/Eight';
import Ten from './Pages/Ten';
import Eleven from './Pages/Eleven';
import Contact from './Pages/Contact';
import Footer from './Pages/Footer';
import MessageBox from './Pages/MessageBox';






/**
 * The main layout component. It renders a Navbar at the top
 * and a div with class "content" which contains all the routes.
 *
 * @returns {React.ReactElement} The Layout component.
 */
function Layout() {
  return (
    <>
      <Navbar /> {/* Navbar will be shown on every page */}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/" element={<Secondpage/>} />
          <Route path="/" element={<Thirdpage/>} />
          <Route path="/" element={<Fourth/>} />
          <Route path="/" element={<Five/>} />
          <Route path="/" element={<Six/>} />
       
          <Route path="/" element={<Eight/>} />
          <Route path="/" element={<Ten/>} />
          <Route path="/" element={<Eleven/>} />
          <Route path="/" element={<Contact/>} />
          <Route path="/" element={<Footer/>} />
          <Route path="/" element={<MessageBox/>} />
        </Routes>
      </div>
     
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout />
      </Router>
  );
}
(
<div>
<MessageBox />
{/* Other content */}
</div>
);


export default App;
