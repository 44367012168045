import '../css/Fourth.css';
import Image1 from '../comp/logo file/compliance-analyst-professional-focus.png';
import Image2 from '../comp/logo file/data-analyst.png';

function Fourth() {
    return (
        <>
            <div className="headline-image-section">
                <div className="headline-left">
                    <div className="headline-item">
                        <h1 className="animated-header">Why Choose DMS RCM?</h1>
                        <p className="animated-text">Overall, DMS RCM can help ensure that your revenue cycle operates smoothly and effectively, freeing you to focus on patient care.</p>
                    </div>
                    <div className="headline-item">
                        <h1 className="animated-header">Compliance</h1>
                        <p className="animated-text">DMS RCM stays updated on regulatory changes, helping you navigate complex healthcare laws and ensuring compliance to avoid costly penalties.</p>
                    </div>
                    <div className="headline-item">
                        <h1 className="animated-header">Data Analytics</h1>
                        <p className="animated-text">They leverage advanced analytics to identify trends and areas for improvement, enhancing decision-making and financial planning.</p>
                    </div>
                    <button>Learn More</button>
                    {/* Learn More Button Added Here */}
                    <div className="learn-more-container">
                        <button className="learn-more-btn">Learn More</button>
                    </div>
                </div>
                <div className="headline-right">
                    <div className="image-wrapper">
                        <img src={Image1} alt="Compliance Analyst" className="main-image" />
                        <img src={Image2} alt="Data Analyst" className="overlay-image" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Fourth;