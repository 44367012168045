import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../comp/logo file/img1.jpeg'; // Adjust the path to your logo
import '../css/Navbar.css';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState({ services: false, about: false, news: false, impact: false });

  const toggleDropdown = (section) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="nav-links">
        {/* Services Dropdown */}
        <div
          className="dropdown"
          onMouseEnter={() => toggleDropdown('services')}
          onMouseLeave={() => toggleDropdown('services')}
        >
          <span className="nav-item">Services</span>
          {dropdownOpen.services && (
            <div className="dropdown-content">
              <Link to="/service1">Patient Access Services</Link>
              <Link to="/service2">Patient Scheduling Services</Link>
              <Link to="/service3">Financial Clearance Services</Link>
              <Link to="/service4">HIM and Clinical Services</Link>
              <Link to="/service5">Medical Coding Services</Link>
            </div>
          )}
        </div>

        {/* About Dropdown */}
        <div
          className="dropdown"
          onMouseEnter={() => toggleDropdown('about')}
          onMouseLeave={() => toggleDropdown('about')}
        >
          <span className="nav-item">DMS Platform</span>
          {dropdownOpen.about && (
            <div className="dropdown-content">
              <Link to="/about-company">Company</Link>
              <Link to="/about-team">Team</Link>
              <Link to="/about-careers">Careers</Link>
            </div>
          )}
        </div>

        {/* About Us Dropdown */}
        <div
          className="dropdown"
          onMouseEnter={() => toggleDropdown('news')}
          onMouseLeave={() => toggleDropdown('news')}
        >
          <span className="nav-item">About Us</span>
          {dropdownOpen.news && (
            <div className="dropdown-content">
              <Link to="/latest-news">Latest News</Link>
              <Link to="/press-release">Press Release</Link>
              <Link to="/media-coverage">Media Coverage</Link>
            </div>
          )}
        </div>

        {/* Company Dropdown */}
        <div
          className="dropdown"
          onMouseEnter={() => toggleDropdown('impact')}
          onMouseLeave={() => toggleDropdown('impact')}
        >
          <span className="nav-item">Company</span>
          {dropdownOpen.impact && (
            <div className="dropdown-content">
              <Link to="/case-studies">Leadership</Link>
              <Link to="/client-testimonials">News & Events</Link>
              <Link to="/social-impact">Diversity & Inclusion</Link>
              <Link to="/case-studies">Associations & Partners</Link>
              <Link to="/client-testimonials">Sustainability Initiatives</Link>
              <Link to="/social-impact">Careers</Link>
            </div>
          )}
        </div>
      </div>

      {/* Search Bar */}
      <div className="search-bar">
        <input type="text" placeholder="Search..." className="search-input" />
        <button type="button" className="search-button">Search</button>
      </div>
    </nav>
  );
};

export default Navbar;