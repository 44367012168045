import logo1 from '../comp/logo file/Group 24.png';

function Secondpage() {
    return ( 
        <div className='Container'>
        <div className='row'>
            <div className='col-md-12'>
                <div className="logo-description-section">
                    <div className="logo-box">
                        <img src={logo1} alt="Logo1" className="logo1" />
                    </div>
                    <div className="description-box">
                        <h1 className="description">A Platform that sets the standard for healthcare performance</h1>
                        <p className="long-description">
                        (DMS) is a digital solution that allows healthcare providers to store, manage, and track documents electronically. In the healthcare context, this system helps to handle patient records, insurance forms, billing information, and more, ensuring easy access and compliance with regulations like HIPAA (Health Insurance Portability and Accountability Act).
                        (RCM) refers to the financial processes that healthcare organizations use to track patient care episodes from registration and appointment scheduling to the final payment of a balance. It’s crucial for optimizing the financial performance of healthcare providers.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

     );
}

export default Secondpage;