import '../css/Five.css'
import '../comp/logo file/641d54e9fdbb231e0177fa71_revenue-cycle-management-steps-bannerr.png'
import ImageGO from '../Images/1646324224664.png'
import ImageGO2 from '../Images/guide-to-lead-generation-featured-image.jpg'
import ImageGO3 from '../Images/iStock-1399794091-1024x384.jpg'
function Five() {
   
    return ( 
    <>
   <div className="container">
  {/* Headline and Subheadline */}
  <div className="text-center page-section">
    <h1 className="headline">Opportunities</h1>
    <p className="sub-headline">To Make An Impact Are Endless</p>

    {/* Buttons */}
    <div className="button-group">
      <button className="custom-button">News</button>
      <button className="custom-button">Blog</button>
    </div>
  </div>

  {/* Boxes Section */}
  <div className="row box-section">
    {/* First Box */}
    <div className="col-md-4 mb-3">
      <div className="box">
        <img src={ImageGO} alt="Box 1" className="img-fluid" />
        <p className="box-description">What is Healthcare Revenue Cycle Management? Revenue Cycle Management (RCM) refers to the financial process within the healthcare industry....</p>
        <a href="#box1" className="box-link">Read More</a>
      </div>
    </div>

    {/* Second Box */}
    <div className="col-md-4 mb-3">
      <div className="box">
        <img src={ImageGO2} alt="Box 2" className="img-fluid" />
        <p className="box-description">GeBBS Healthcare Solutions. Inc (ChrysCapital Portfolio Company) a leading provider of technology enabled Revenue Cycle....</p>
        <a href="#box2" className="box-link">Read More</a>
      </div>
    </div>

    {/* Third Box */}
    <div className="col-md-4 mb-3">
      <div className="box">
        <img src={ImageGO3} alt="Box 3" className="img-fluid" />
        <p className="box-description">The increasing frequency threat of cyberattacks impacted a significant number of healthcare facilities. These attacks complicated healthcare.....</p>
        <a href="#box3" className="box-link">Read More</a>
      </div>
    </div>
  </div>
</div>
    </> );
}

export default Five;