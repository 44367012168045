import '../css/Eight.css';
import Flippic from '../Images/Circleimage4.jpg';

function Eight() {
  return (
    <div className="container1">
      <div className="row">
        <div className="col-md-12">
          <div className="content1">
            <div className="text-section1">
              <h1 className="headline1">
                DMS RCM Healthcare Named a "Leader" In Medical Coding Operations PEAK Matrix Assessment 2023
              </h1>
              <p className="paragraph1">
                DMS RCM Healthcare has consistently demonstrated excellence in medical coding operations, setting benchmarks in accuracy and efficiency.
              </p>
              <button className="learn-more-button1">Learn More</button>
            </div>
            <div className="image-section1">
              <div className="circle1">
                <img
                  src={Flippic}
                  alt="DMS RCM Healthcare"
                  className="circle-image animated-zoom"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eight;