import { useState, useEffect } from "react";
import '../css/Ten.css';

// Importing team member images
import YashImage from '../Images/Yash.jpeg'; 
import SourabhImage from '../Images/Sourabh.jpg';
import SumitImage from '../Images/Sumit.jpg';
import VanshikaImage from '../Images/vanshikha web.jpg';
import SwatiImage from '../Images/swati web.jpg';
import GauravImage from '../Images/gaurav web.jpg';
import DeeptiImage from '../Images/deepti web.jpg';
import PiyushImage from '../Images/piyush.jpg';
import GovindImage from '../Images/Govind.jpg';
import DikshaImage from '../Images/DikshaImage.jpeg'

function Ten() {
  const teamMembers = [
    {
      name: 'Yash Saxena',
      role: 'Operation Manager',
      image: YashImage,
      description: 'Skilled developer specializing in front-end technologies.',
    },
    {
      name: 'Sourabh Agrawal',
      role: 'Acting Team Lead',
      image: SourabhImage,
      description: 'Leader with a strong background in project management.',
    },
    {
      name: 'Sumit Alwani',
      role: 'Senior Billing Executive',
      image: SumitImage,
      description: 'Expert in billing processes and client relations.',
    },
    {
      name: 'Vanshika Tongare',
      role: 'Coding Specialist',
      image: VanshikaImage,
      description: 'Proficient in multiple programming languages.',
    },
    {
      name: 'Swati Arya',
      role: 'Senior Account Receivable',
      image: SwatiImage,
      description: 'Specialized in account management and receivables.',
    },
    {
      name: 'Gaurav Kumawat',
      role: 'Accounts and Billing Executive',
      image: GauravImage,
      description: 'Focused on accuracy and client satisfaction.',
    },
    {
      name: 'Deepti Sharma',
      role: 'Accounts and Billing Executive',
      image: DeeptiImage,
      description: 'Detail-oriented with expertise in financial reporting.',
    },
    {
      name: 'Piyush Joshi',
      role: 'Accounts and Billing Executive',
      image: PiyushImage,
      description: 'Experienced in billing cycles and account management.',
    },
    {
      name: 'Govind Sharma',
      role: 'Website Developer',
      image: GovindImage,
      description: 'Strong analytical skills in financial analysis.',
    },
    {
      name: 'Diksha Haldiya',
      role: 'Accounts and Billing Executive',
      image: DikshaImage,
      description: 'Strong analytical skills in financial analysis.',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically move to the next slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % teamMembers.length);
    }, 3000); // Slide every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [teamMembers.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % teamMembers.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + teamMembers.length) % teamMembers.length);
  };

  return (
    <div className="slider-container">
      <h1 className="slider-title">Our Team</h1>
      <div className="slider-wrapper">
        <button className="slider-button prev" onClick={handlePrev}>
          &#10094;
        </button>
        <div className="slider-content">
          <img
            src={teamMembers[currentIndex].image}
            alt={teamMembers[currentIndex].name}
            className="slider-image"
          />
          <div className="slider-text">
            <h2>{teamMembers[currentIndex].name}</h2>
            <p>{teamMembers[currentIndex].role || "Role not provided"}</p>

          </div>
        </div>
        <button className="slider-button next" onClick={handleNext}>
          &#10095;
        </button>
      </div>
    </div>
  );
}

export default Ten;